import React from 'react';
import Helmet from 'react-helmet';

import Layout from '../components/layout';
import HeaderGeneric from '../components/HeaderGeneric';
import pic04 from '../assets/images/pic04.jpg';

class Generic extends React.Component {
  render() {
    return (
      <Layout>
        <Helmet title="Photography " />
        <HeaderGeneric />
        <div id="main">
          <section id="content" className="main">
            <span className="image main">
              <img src={pic04} alt="" />
            </span>
            <h2>Photography Page</h2>
            <p>
              Donec eget ex magna. Interdum et malesuada fames ac ante ipsum
              primis in faucibus. Pellentesque venenatis dolor imperdiet dolor
              mattis sagittis. Praesent rutrum sem diam, vitae egestas enim
              auctor sit amet. Pellentesque leo mauris, consectetur id ipsum sit
              amet, fergiat. Pellentesque in mi eu massa lacinia malesuada et a
              elit. Donec urna ex, lacinia in purus ac, pretium pulvinar mauris.
              Curabitur sapien risus, commodo eget turpis at, elementum
              convallis fames ac ante ipsum primis in faucibus.
            </p>
            <p>
              Pellentesque venenatis dolor imperdiet dolor mattis sagittis.
              Praesent rutrum sem diam, vitae egestas enim auctor sit amet.
              Consequat leo mauris, consectetur id ipsum sit amet, fersapien
              risus, commodo eget turpis at, elementum convallis elit enim
              turpis lorem ipsum dolor sit amet feugiat. Phasellus convallis
              elit id ullamcorper pulvinar. Duis aliquam turpis mauris, eu
              ultricies erat malesuada quis. Aliquam dapibus, lacus eget
              hendrerit bibendum, urna est aliquam sem, sit amet est velit quis
              lorem.
            </p>
            <h2>Tempus veroeros</h2>
            <p>
              Cep risus aliquam gravida cep ut lacus amet. Adipiscing faucibus
              nunc placerat. Tempus adipiscing turpis non blandit accumsan eget
              lacinia nunc integer interdum amet aliquam ut orci non col ut ut
              praesent. Semper amet interdum mi. Phasellus enim laoreet ac ac
              commodo faucibus faucibus. Curae ante vestibulum ante. Blandit.
              Ante accumsan nisi eu placerat gravida placerat adipiscing in
              risus fusce vitae ac mi accumsan nunc in accumsan tempor blandit
              aliquet aliquet lobortis. Ultricies blandit lobortis praesent
              turpis. Adipiscing accumsan adipiscing adipiscing ac lacinia cep.
              Orci blandit a iaculis adipiscing ac. Vivamus ornare laoreet odio
              vis praesent nunc lorem mi. Erat. Tempus sem faucibus ac id. Vis
              in blandit. Nascetur ultricies blandit ac. Arcu aliquam. Accumsan
              mi eget adipiscing nulla. Non vestibulum ac interdum condimentum
              semper commodo massa arcu.
            </p>
          </section>
        </div>
      </Layout>
    );
  }
}

export default Generic;
